.cardloader-pagination {
  display: flex;
  justify-content: center;
  margin: 1em 0;
}

@media only screen and (max-width: 767.99px) {
  .cardloader-main-container {
    max-width: 100%;
    margin: auto 1.5rem;
  }
}

@media only screen and (min-width: 768px) {
  .cardloader-main-container {
    max-width: 80%;
    margin: auto;
  }
}
