#navbar-container {
  padding: 0.6rem 0.75rem;
}

#navbar {
  padding-top: 0%;
  padding-bottom: 0%;
}

.navbar-brand {
  font-weight: 700;
  display: flex;
  align-items: center;
}

#navbar-logo {
  width: 60%;
}

#nav-bar-toggle {
  border: none;
}

/* Mobile - Collapsed Menu */
@media only screen and (max-width: 767.99px) {
  #nav-link-home,
  #nav-link-add-item,
  #nav-link-portfolio {
    display: inline-block;
    color: #21467b;
    font-family: "Roboto", "Segoe UI", sans-serif;
    text-decoration: none;
    background-image: linear-gradient(#1dcdfe, #1dcdfe);
    background-size: 0 2px, auto;
    background-repeat: no-repeat;
    background-position: left bottom;
    transition: all 0.3s ease-out;
  }

  #nav-link-home:hover,
  #nav-link-add-item:hover,
  #nav-link-portfolio:hover {
    background-size: 30% 2px, auto;
  }
}

/* Tablet, Laptop, Desktop - Open Navbar */
@media only screen and (min-width: 768px) {
  #nav-link-home,
  #nav-link-add-item,
  #nav-link-portfolio {
    display: inline-block;
    color: #21467b;
    font-family: "Roboto", "Segoe UI", sans-serif;
    text-decoration: none;
    background-image: linear-gradient(#1dcdfe, #1dcdfe);
    background-size: 0 2px, auto;
    background-repeat: no-repeat;
    background-position: center bottom;
    transition: all 0.3s ease-out;
  }

  #nav-link-home:hover,
  #nav-link-add-item:hover,
  #nav-link-portfolio:hover {
    background-size: 100% 2px, auto;
  }
}
