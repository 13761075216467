.welcome-container {
  background-color: #e9ecef;
  padding: 2rem 1rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

#welcome-button {
  background-color: #21467b;
  border-color: #21467b;
  color: #ffffff;
  font-weight: 400;
}

#welcome-header {
  font-weight: 700;
  margin: 0 0 10px 0;
}

.welcome-action-group {
  display: flex;
  font-family: "Roboto", "Segoe UI", sans-serif;
  align-items: center;
  margin-top: 20px;
}

#welcome-button {
  font-size: 1rem;
}

/* Mobile */
@media only screen and (max-width: 767.99px) {
  .welcome-container {
    max-width: 100%;
    margin: 0 0 2rem 0;
  }

  .welcome-action-group {
    flex-direction: column;
    align-items: start;
  }

  #welcome-button {
    margin: 10px 0 20px 0;
  }
}

/* Tablet, Laptop, and Desktop */
@media only screen and (min-width: 768px) {
  .welcome-container {
    max-width: 80%;
    margin: 2rem auto;
  }

  .welcome-action-group {
    flex-direction: row;
  }

  #portfolio-link {
    margin: 0 0 0 20px;
  }
}
