/* General */
.detailed-card-container {
  display: flex;
}

.detailed-p-tags {
  font-weight: bold;
}

.tablet-view {
  display: flex;
  flex-direction: row;
  flex: 2 1 auto;
}

#detailed-card-1-id,
#detailed-card-2-id {
  font-family: "Oswald", "Segoe UI", sans-serif;
}

.detailed-card-body-1,
.detailed-card-body-2 {
  font-family: "Roboto", "Segoe UI", sans-serif;
}

/* Image */
.detailed-card-image-container {
  padding: 2rem;
  flex: 1 1 auto;
  max-width: 640px;
}

#detailed-card-image {
  max-width: 100%;
  height: auto;
  aspect-ratio: 4/3;
  object-fit: cover;
}

/* Card 1 */
#detailed-card-1 {
  border: none;
  flex: 3 1 auto;
}

.detailed-card-body-1 {
  display: flex;
  flex-direction: column;
}

#detailed-star-rating {
  display: flex;
  flex-direction: row;
}

/* Card 2 */
#detailed-card-2 {
  border: none;
  flex: 1 1 auto;
  min-width: 150px;
}

.detailed-card-body-2 {
  display: flex;
  flex-direction: column;
}

.detailed-id-and-dates {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
}

.updated-on,
.created-on {
  display: flex;
  flex-direction: column;
}

/* Misc */
.separator {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.flex-1 {
  flex: 1 1 auto;
}

.flex-2 {
  flex: 2 1 auto;
}

.flex-3 {
  flex: 3 1 auto;
}

/* Mobile */
@media only screen and (max-width: 767.99px) {
  .detailed-card-container {
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid rgb(0, 0, 0, 0.175);
    box-shadow: none;
    border-radius: 0%;
  }

  .detailed-card-image-container {
    padding: 0;
    border-radius: 0%;
    margin-top: 1px;
    max-width: 100%;
  }

  #detailed-card-image {
    border-radius: 0%;
  }

  .tablet-view {
    flex-direction: column;
    padding: 1rem 1rem;
  }

  .separator {
    margin: 30px 10px;
  }

  .detailed-card-description {
    margin: 10px 0 30px 0;
  }

  .detailed-card-price {
    margin: 0 0 20px 0;
  }

  .detailed-card-body-2 {
    display: flex;
    flex-direction: row;
  }

  #detailed-card-stack {
    align-self: flex-end;
    margin-bottom: 5px;
  }
}

/* Tablet & Laptop */
@media only screen and (min-width: 768px) {
  .detailed-card-container {
    flex-direction: column;
    margin: 3em auto;
    max-width: 600px;
    padding: 1.2rem;
    border: 1px solid #fbfbfc;
    border-radius: 20px;
    box-shadow: 0 0 8px 1px rgba(43, 37, 37, 20%);
  }

  .tablet-view {
    flex-direction: row;
    max-width: 600px;
    padding: 2rem;
  }

  #detailed-card-image {
    border-radius: 16px;
  }

  .detailed-card-description {
    margin: 20px 0 20px 0;
  }

  .detailed-card-price {
    margin: 25px 0 20px 0;
  }

  .separator {
    margin: 0 50px;
  }

  .detailed-card-body-2 {
    display: flex;
    flex-direction: column;
  }

  #detailed-card-stack {
    align-self: flex-start;
  }
}

/* Desktop */
@media only screen and (min-width: 1200px) {
  .detailed-card-container {
    margin: 3em auto;
    display: flex;
    flex-direction: row;
    max-width: none;
    width: 90%;
  }

  .tablet-view {
    flex-direction: row;
    max-width: none;
    padding: 2rem;
  }

  .detailed-card-description {
    margin: none;
  }

  .detailed-card-price {
    margin: none;
  }
}

@media only screen and (min-width: 1400px) {
  .detailed-card-container {
    width: 85%;
  }
}

@media only screen and (min-width: 1810px) {
  .detailed-card-container {
    width: 80%;
  }
}
