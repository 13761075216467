#add-item-button {
  background-color: #21467b;
  border-color: #21467b;
}

#add-item-button:hover {
  background-color: #142a4a;
  border-color: #142a4a;
}

#add-item-cancel-button {
  width: 120px;
  height: 38px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: #b31312;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  background: #b31312;
}

#add-item-cancel-button,
#add-item-cancel-button span {
  transition: 200ms;
}

#add-item-cancel-button .text {
  transform: translateX(15px);
  color: white;
}

#add-item-cancel-button .icon {
  position: absolute;
  border-left: 1px solid white;
  transform: translateX(80px);
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#add-item-cancel-button svg {
  width: 15px;
  fill: #eee;
}

#add-item-cancel-button:hover {
  background: #b31312;
}

#add-item-cancel-button:hover .text {
  color: transparent;
}

#add-item-cancel-button:hover .icon {
  width: 120px;
  border-left: none;
  transform: translateX(0);
}

#add-item-cancel-button:focus {
  outline: none;
}

#add-item-cancel-button:active .icon svg {
  transform: scale(0.8);
}

/* Mobile */
@media only screen and (max-width: 767.99px) {
  #add-item-cancel-button {
    width: 90px;
  }

  #add-item-cancel-button .text {
    transform: translateX(20px);
  }

  #add-item-cancel-button .icon {
    visibility: hidden;
  }
}

/* Table, Laptop, Desktop */
@media only screen and (min-width: 768px) {
  #add-item-cancel-button {
    width: 120px;
  }

  #add-item-cancel-button .text {
    transform: translateX(15px);
  }

  #add-item-cancel-button .icon {
    visibility: visible;
  }
}
